import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { Suspense, lazy } from "react";

const Home = lazy(() => import("./routes/home/Home"));
const Pricing = lazy(() => import("./routes/pricing/Pricing.js"));
const Error404 = lazy(() => import("./routes/errors/Error404.js"));
const Contact = lazy(() => import("./routes/contact/Contact.js"));

export default function App() {
  return (
    <Router>
      <Suspense fallback={<div id="intro"></div>}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/contact" component={Contact} />
          <Route component={Error404} />
        </Switch>
      </Suspense>
    </Router>
  );
}
